import React from "react";
import Header from "../../component/Header";

const Ambassador = () => {
    return (
        <>
            <Header theme="dark" />
        </>
    );
};

export default Ambassador;
